import { useEffect, useState } from "react";
import { SiteMeta } from "./site-meta";
import { AnthologyProps } from "./types";
import R from "./resources";

function toggleDarkMode() {
  const htmlElements = document.getElementsByTagName("html");
  if (!(htmlElements && htmlElements.length > 0)) {
    return;
  }
  const htmlElement = htmlElements[0];
  const currentTheme = htmlElement.getAttribute("data-bs-theme");
  htmlElement.setAttribute(
    "data-bs-theme",
    currentTheme === "dark" ? "light" : "dark",
  );
}

const SiteNavigationBar: React.FunctionComponent<
  AnthologyProps
> = ({ metadata: topic }) => {
  const [siteMeta, setSiteMeta] = useState<SiteMeta | null>(null);

  useEffect(() => {
    SiteMeta.fetch().then((response) => setSiteMeta(response));
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          {siteMeta?.siteName()}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* topics menu */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {R.Label.TopicsMenuTitle}
              </a>
              <ul className="dropdown-menu">
                {siteMeta &&
                  Object.keys(siteMeta.siteMap).map((aTopic) => (
                    <li key={aTopic}>
                      <a
                        className="dropdown-item"
                        href={aTopic ? `/${aTopic}/` : "/"}
                      >
                        {siteMeta?.nameForTopic(aTopic)}
                      </a>
                    </li>
                  ))}
              </ul>
            </li>
            {topic && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {siteMeta?.nameForTopic(topic)} {R.Label.PagesMenuTitle}
                </a>
                <ul className="dropdown-menu">
                  {siteMeta &&
                    siteMeta.siteMap[topic].map((page) => (
                      <li key={page.url}>
                        <a
                          className="dropdown-item"
                          href={`/${topic}/${page.url}`}
                        >
                          {page.title}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            )}
          </ul>
          {/*<ul className="navbar-nav me-2 mb-2 mb-lg-0">
            <li className="nav-item">
              <button className="btn nav-link" onClick={() => toggleDarkMode()}>
                <i className="bi bi-moon-stars"></i>
              </button>
            </li>
          </ul>*/}
          {/* <form className="d-flex" role="search">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form> */}
        </div>
      </div>
    </nav>
  );
};

export default SiteNavigationBar;
