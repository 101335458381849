enum Label {
  TopicsMenuTitle = "Topics",
  PagesMenuTitle = "Pages",
  PageUpdatedLabel = "updated",
  AddToPracticeRosterButtonLabel = "Add to Practice Roster"
}

const resources = {
  Label,
};

export default resources;
